import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { GET_USER_DATA, REFRESH_TOKEN } from "~/graphql/LoginRegisterUser";
import { useMutation } from "@vue/apollo-composable";
const { onLogout } = useApollo();

export const useBcnAuth = defineStore("bcnAuth", () => {
  const isLoggedIn = ref(false);
  const authLoading = ref(false);
  let isRefreshingLoginStatus = false;

  // let userCookie = ref();
  // let tokenCookie = ref();
  // let refreshTokenCookie = ref();
  // if (process.client) {
  const userCookie = useCookie("bcn.user", {
    maxAge: 60 * 60 * 24 * 365,
  });
  const tokenCookie = useCookie("apollo:bcn.token", {
    maxAge: 3600,
  });
  const refreshTokenCookie = useCookie("apollo:bcn.refreshToken", {
    maxAge: 60 * 60 * 24 * 365,
  });
  // }

  const updateUserFavoritesFromQuery = () => {
    if (userCookie?.value?.userId) {
      const { loading, onResult, onError } = useQuery(GET_USER_DATA, {
        id: userCookie.value.userId,
      });

      onResult((result) => {
        userCookie.value.favorites = result?.data?.user?.favorites;
        console.log("updateUserFavoritesFromQuery result:", result);
      });

      onError((error) => {
        console.error("updateUserFavoritesFromQuery error:", error);
      });
    }
  };

  const {
    mutate: refreshMutate,
    loading: refreshLoading,
    error: refreshError,
    called: refreshCalled,
    onDone: refreshOnDone,
    onError: refreshOnError,
  } = useMutation(REFRESH_TOKEN);

  const refreshAuthToken = (refreshToken) => {
    refreshMutate({
      jwtRefreshToken: refreshToken,
    });

    refreshOnDone((result) => {
      tokenCookie.value = result?.data?.refreshJwtAuthToken?.authToken ?? null;

      if (tokenCookie.value) {
        isLoggedIn.value = true;

        updateUserFavoritesFromQuery();
      }
    });

    refreshOnError((error) => {
      console.error("Error refreshing token:", error);

      refreshTokenCookie.value = null;
    });
  };

  const logout = () => {
    onLogout();

    refreshTokenCookie.value = null;

    userCookie.value = null;

    isLoggedIn.value = false;
  };

  const refreshLoginStatus = async () => {
    if (isRefreshingLoginStatus === true) {
      return;
    }
    isRefreshingLoginStatus = true;
    authLoading.value = true;

    if (tokenCookie.value) {
      isLoggedIn.value = true;
    } else if (refreshTokenCookie.value) {
      refreshAuthToken(refreshTokenCookie.value);
    }
    isRefreshingLoginStatus = false;
    authLoading.value = false;
    return;
  };

  return {
    authLoading,
    isLoggedIn,
    logout,
    refreshLoginStatus,
    refreshTokenCookie,
    tokenCookie,
    updateUserFavoritesFromQuery,
    userCookie,
  };
});
