import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import { UPDATE_FAVORITES } from "~/graphql/Favorites";
import { useBcnAuth } from "./bcnAuth";

export const useFavorites = defineStore("favorites", () => {
  const bcnAuth = useBcnAuth();
  const { isLoggedIn, userCookie } = storeToRefs(bcnAuth);

  const notificationIsOpen = ref(false);
  const notificationProductName = ref("");
  const notificationImageUrl = ref("");
  const notificationCountdownProgress = ref(100);
  const notificationCurrentInterval = ref(null);
  const refreshing = ref(false);

  const queue = ref([]);
  const processingQueue = ref(false);
  const debounceDelay = 2000;
  let debounceTimer = null;

  /**
   * Enqueues a request with a specific action and ID into the queue.
   * The request is processed if the queue is not already being processed.
   *
   * @param {Object} request - The request object to be enqueued.
   * @param {'add'|'remove'} request.action - The action type of the request. Must be either 'add' or 'remove'.
   * @param {number} request.id - The ID associated with the request. Must be an integer.
   */
  const enqueueRequest = (request) => {
    queue.value.push(request);
    console.log('request', request);

    if (!processingQueue.value) {
      processQueue();
    }
  };

  const processQueue = async () => {
    if (processingQueue.value) return;

    if (queue.value.length === 0) {
      // If the queue is empty, reset the processing state and do nothing.
      processingQueue.value = false;
      return;
    }

    processingQueue.value = true;

    // Process the first request immediately if it's the only item in the queue
    // to increase perceived resposiveness
    if (queue.value.length === 1) {
      await processRequests();
    } else {
      // Debounce subsequent requests
      debounceSubsequentRequests();
    }
  };

  const processRequests = async () => {
    try {
      // Batch processing: Process all requests in the queue at once
      const requestsToProcess = [...queue.value];
      queue.value = []; // Clear the queue

      const userId = userCookie?.value?.userId ?? -1;

      let cookieFavorites = [];
      if (isValidJSON(userCookie.value.favorites)) {
        const parsedFavorites = JSON.parse(userCookie.value.favorites);
        if (Array.isArray(parsedFavorites)) {
          cookieFavorites = parsedFavorites.filter(
            (item) => typeof item === "number",
          );
        }
      }
      let localFavorites = new Set(cookieFavorites);

      requestsToProcess.forEach((request) => {
        if (request.action === "add") {
          localFavorites.add(request.id);
        }
        if (request.action === "remove") {
          localFavorites.delete(request.id);
        }
      });

      const newFavorites = JSON.stringify([...localFavorites]);

      userCookie.value.favorites = newFavorites;
      await updateFavorites(userId, newFavorites);
    } catch (error) {
      console.error(error);
      bcnAuth.refreshLoginStatus();
      bcnAuth.updateUserFavoritesFromQuery();
    } finally {
      processingQueue.value = false;
      if (queue.value.length > 0) {
        processQueue(); // Process new items added during processing
      }
    }
  };

  const debounceSubsequentRequests = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      processRequests();
    }, debounceDelay);
  };

  const refreshAll = async () => {
    refreshing.value = true;
    try {
      await refreshNuxtData();
    } finally {
      refreshing.value = false;
    }
  };

  const notificationStartCountdown = () => {
    if (notificationCurrentInterval.value) {
      clearInterval(notificationCurrentInterval.value);
    }

    const intervalTime = 50;
    const decrement = (intervalTime / 8000) * 100;
    notificationCurrentInterval.value = setInterval(() => {
      notificationCountdownProgress.value -= decrement;
      if (notificationCountdownProgress.value <= 0) {
        notificationCountdownProgress.value = 0;
        notificationIsOpen.value = false;
        clearInterval(notificationCurrentInterval.value);
      }
    }, intervalTime);
  };

  const openNotification = () => {
    notificationIsOpen.value = true;
  };
  const closeNotification = () => {
    notificationIsOpen.value = false;
  };

  const toggleFavoriteProduct = (product) => {

    const userId = userCookie?.value?.userId ?? -1;
    let newFavorites = "";

    const cookieFavorites = isValidJSON(userCookie.value.favorites)
      ? JSON.parse(userCookie.value.favorites)
      : [];

    const productIsFavorite = isFavorite(product.id, cookieFavorites);

    if (productIsFavorite) {
      enqueueRequest({ action: "remove", id: product.id });
      return;
    } else {
      enqueueRequest({ action: "add", id: product.id });

      notificationCountdownProgress.value = 100;
      notificationProductName.value = product.title;
      notificationImageUrl.value = product.thumbnail;
      openNotification();

      return;
    }
  };

  const isFavorite = (id, cookieFavorites) => {
    // console.log('Stack Trace:', new Error().stack);
    // console.log('id', id);
    // console.log('cookieFavorites', cookieFavorites);

    return cookieFavorites && Array.isArray(cookieFavorites)
      ? cookieFavorites?.some((itemId) => {
          return itemId === id;
        })
      : false;
  };

  const {
    mutate: favoritesMutate,
    loading: favoritesSubmitting,
    error: favoritesError,
    called: favoritesCalled,
    onDone: favoritesOnDone,
    onError: favoritesOnError,
  } = useMutation(UPDATE_FAVORITES);

  const updateFavorites = (userId, newFavorites) => {

    return new Promise((resolve, reject) => {
      if (!userId || !newFavorites || isLoggedIn.value !== true) {
        console.error(
          "updateFavorites error: Invalid input or user not logged in.",
        );
        reject("Invalid input or user not logged in.");
        return;
      }

      favoritesMutate({
        uniqueId: generateRandomString(8),
        userId: userId,
        favorites: newFavorites,
      });

      favoritesOnDone((result) => {

        const resultFavorites = isValidJSON(
          result?.data?.updateUserFavorites?.user?.favorites,
        )
          ? result?.data?.updateUserFavorites?.user?.favorites
          : JSON.stringify([]);

        console.log('resultFavorites', resultFavorites);
        userCookie.value.favorites = resultFavorites;
        resolve(resultFavorites);
      });

      favoritesOnError((error) => {
        console.error("favoritesOnError error", error);
        refreshAll();
        reject(error);
      });
    });
  };

  return {
    closeNotification,
    enqueueRequest,
    favoritesSubmitting,
    isFavorite,
    notificationCountdownProgress,
    notificationCurrentInterval,
    notificationImageUrl,
    notificationIsOpen,
    notificationProductName,
    notificationStartCountdown,
    openNotification,
    processingQueue,
    toggleFavoriteProduct,
  };
});
